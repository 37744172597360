import React, { useState } from 'react';
import { environment } from '../environments/environment';
import Typography from '@material-ui/core/Typography';
import { LoadingSpinner } from './LoadingSpinner';
import { useStyles } from './hooks/useStyles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useData, useNode } from './firebase';
import { EndMessage } from './EndMessage';
import SideBar from './SideBar';
import { useFilter } from '@parm/react/filter-control';
import CardContent from '@material-ui/core/CardContent';
import { storage } from './storage';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { useSortState } from './hooks';
import Markdown from 'markdown-to-jsx';
import { AdventureOptionCard } from './AdventureOptionCard';
import { useMatch } from 'react-router-dom';
import { NodeEntity } from './Node/NodeDefinition';
import { registerFilterOperator } from './filter/filter-operators';
import { filterFnFactory } from './filter/filter-fn-factory';
import { notEqualFilterOperator } from './filter/operators/not-equal-filter-operator';
import { includesFilterOperator } from './filter/operators/includes-filter-operator';
import { get } from '@prmichaelsen/ts-utils';
import { useSettings } from './Settings';

// register filter operators
registerFilterOperator(notEqualFilterOperator);
registerFilterOperator(includesFilterOperator);

const compareByTime = (a: NodeEntity, b: NodeEntity) => {
  const result = +a.createTime.toDate() - +b.createTime.toDate();
  return storage.sort() ? result : -result;
}

export default function Adventure(props) {
  const classes = useStyles();
  const [afterSize, setAfterSize] = useState(4);
  const [beforeSize, setBeforeSize] = useState(1);
  const match = useMatch('/nodes/:id');
  const focus = match && match.params['id'] || 'create';
  const { 
    state: data,
    createOption, updateNode,
  } = useData();
  const fetchAfter = () => 
    setAfterSize(afterSize + 3); 
  const fetchBefore = () => 
    setBeforeSize(beforeSize + 3); 

  const { filter, control } = useFilter();

  const filterNode = useFeed(data.nodes);
  const feedFilter = filterFnFactory(filterNode, data.nodes);
  const sortFilter = useSortFilter(data.nodes);
  const rootId = data.root && data.root.id;
  const focusNode = data.nodes.find(n => n.id === focus);
  const nodes: NodeEntity[] = data.nodes
    .filter(feedFilter)
    .filter(v => filter(v.text))
    .sort(compareByTime)
    .sort(sortFilter)
    ;

  const { toggleSort, sortAscending } = useSortState();

  const feedMatch = useMatch('/feeds/:feed');
  const isFeedfocused = feedMatch && !!feedMatch.params['feed'];
  const showCreateCard = focus === 'create' && !isFeedfocused;


  const CreateCard = () => (
    <AdventureOptionCard 
      showBackButton={false}
      key={'add'}
      new
      parent={rootId as any}
      createOption={createOption}
      type={'action'}
      text=''
    />
  );

  return (
    <div className={classes.paper}>
      <SideBar/>
      <Typography component="h1" variant="h5">
        <Markdown>
          {environment.header}
        </Markdown>
      </Typography>
      <Typography>
        {environment.metaDescription && (
            <Markdown>
              {environment.metaDescription}
            </Markdown>
        )}
      </Typography>
        <div className={classes.cards}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                {control}
              </Grid>
              <Grid item xs={2}>
                oldest first
                <Switch checked={sortAscending} onChange={toggleSort}/>
              </Grid>
            </Grid>
          </CardContent>
          {showCreateCard && (
            <CreateCard/>
          )}
          {focusNode && (
            <AdventureOptionCard 
              createOption={updateNode as any}
              key={'focus'}
              {...focusNode}
              prev
            />
          )}
          <InfiniteScroll
            dataLength={afterSize}
            next={fetchAfter}
            hasMore={afterSize < nodes.length}
            loader={<LoadingSpinner/>}
            endMessage={<EndMessage/>}
          >
            {nodes.slice(0, afterSize)
              .filter(node => !focusNode || node.id !== focusNode.id)
              .map((node, i) => {
              return (
                <AdventureOptionCard
                  createOption={updateNode as any}
                  key={'after-' + i}
                  {...node}
                  prev
                />
              )
            })}
          </InfiniteScroll>
        </div>
    </div>
  );
}


const useFeed = (nodes: Array<NodeEntity>) => {
  // first try and find an active
  // feed match
  const feedMatch = useMatch('/feeds/:feed');
  const feed = feedMatch && feedMatch.params['feed'];
  if (feedMatch) {
    return nodes.find(n => {
      return feed === n.id;
    });
  }

  // if not, let's see if there is a default
  // filter node configured.
  const settings = useSettings();
  if (settings['filter.default']) {
    const defaultFilterId = settings['filter.default'].value;
    return nodes.find(n => {
      return defaultFilterId === n.id;
    });
  }
  // note: the 'filter.default' behavior (or in fact, any 
  // global setting behavior) should probably be 
  // encapsulated in a plugin

  // else, return no filter.
  return undefined;
}

const useSortFilter = (nodes) => {
  const feedFilterNode = useFeed(nodes);
  let comparorator = (_a, _b) => 0;
  const filter = get(
    feedFilterNode,
    o => o.data,
    o => o.sort
  );
  if (filter) {
    const {
      left: field, op, right: direction
    } = filter;
    if (direction === 'asc') {
      comparorator = (a, b) => a[field] < b[field] ? -1 :  1;
    } else {
      comparorator = (a, b) => b[field] > a[field] ?  1 : -1;
    }
  }
  return comparorator;
}