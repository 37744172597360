import { NodeEntity } from "../../Node/NodeDefinition";
import { Hook, HookCheckResult, HookFn, HookManagerProps, Hooks, ValidationResult } from "./hooks.interface";


export const hookManagerFactory = (props: HookManagerProps) => {
  const { hookType } = props;
  const hooks: Hooks<HookFn> = {};
  /**
   * check if all pre create hooks pass
   */
  const checkHooks = (node: NodeEntity, nodes: Array<NodeEntity>) => {
    const results: HookCheckResult = {
      hookType,
      pass: true,
      error: [],
      warn: [],
      log: [],
    };
    const hookKeys = Object.keys(hooks);
    if (hookKeys.length === 0) {
      return results;
    }
    hookKeys.forEach((key) => {
      const result = hooks[key].hookFn(node, nodes);
      const validationResult: ValidationResult = {
        ...result,
        hookName: key,
      }
      if (result.pass) {
        results.log.push(validationResult);
      } else {
        results.pass = false;
        results.error.push(validationResult);
      }
    });
    return results;
  };

  /**
   * register new view filter hooks that may be used.
   *
   * when a plugin is registered, it may also
   * register a new pre-create filter hook which
   * will then be called on a node before
   * creating it.
   *
   * if the hook returns false, the node
   * is not created.
   *
   * it may make sense to capture some errors
   * or state about why in the future. for now
   * i fail silently.
   */
  const registerHook = (hook: Hook<HookFn>) => {
    const _hook = hooks[hook.hookName];
    if (_hook) {
      const msg = [
        `Could not add ${hookType}.`,
        `'${_hook}' is already bound.`,
      ].join(' ');
      throw new Error(msg);
    }
    hooks[hook.hookName] = hook;
  };

  return {
    checkHooks,
    registerHook,
  };
};