import { get } from "@prmichaelsen/ts-utils";
import { NodeEntity } from "../Node/NodeDefinition";
import { filterOperators } from "./filter-operators";

export type FilterFn  = (node: NodeEntity) => boolean;

/**
 * returns a filter based on the passed
 * filter node and true if no filter is found
 */
export const filterFnFactory: (
  filterNode: NodeEntity | undefined,
  nodes: Array<NodeEntity>
) => FilterFn = (filterNode, nodes) => {
  const filter = get(
    filterNode,
    o => o.data,
    o => o.filter
  );
  if (filter && nodes) {
    return node => {
      /**
       * AND is supported only on the top-level
       * until recursive filter logic is implemented.
       */
      return filter.every(({ left, op, right }) => {
        const filterOperator = filterOperators[op];
        if (filterOperator && node) {
          return filterOperator.filterFnFactory(node, nodes)(left, right);
        } else {
          return true;
        }
      });
    };
  }

  return () => true;
};
