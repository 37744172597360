import { NodeEntity } from '../Node/NodeDefinition';

interface WhereClause {
  left: string,
  op: string,
  right: string,
  // type: 'where',
}
interface JoinClause {
  op: string,
  clauses: FilterClause[],
  type: 'join',
}
// JoinClause not yet implemenetd
type FilterClause = WhereClause;// | JoinClause;
export interface FilterNodeDataType {
  filter: Array<FilterClause>,
}

interface FilterOperators {
  [operator: string]: FilterOperator<any, any>,
}

export const filterOperators: FilterOperators = {
};

export type FilterFn<L, R> = (l: L, r: R) => boolean;

export interface FilterOperator<L, R> {
  filterFnFactory: (n: NodeEntity, nodes: Array<NodeEntity>) => FilterFn<L, R>,
  operator: string,
}

/**
 * register new filter operators that may be used.
 * this is designed in a way that new plugins
 * may define new filter operators.
 * when a plugin is registered, it will also
 * register the new filter operator which
 * will then be available for use in filter
 * nodes.
 */
export const registerFilterOperator = 
  <L, R>(filterOperator: FilterOperator<L, R>) => {
    const op = 
      filterOperators[filterOperator.operator];
    if (op) {
      const msg = [
        `Could not add filterOperator.`,
        `'${op.operator}' is already bound.`,
      ].join(' ');
      throw new Error(msg);
    }
    filterOperators[filterOperator.operator] = filterOperator;
  }