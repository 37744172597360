import { environment } from '../../environments/environment';

export interface PluginConf {
  pluginName: string,
  pluginVersion: string,
  registerPlugin: () => void,
}
export interface Plugins {
  [key: string]: PluginConf
}
export const plugins: Plugins = {
};


export const registerPlugins = () => {
  const { plugins: appPlugins } = environment;
  if (appPlugins) {
    appPlugins.forEach(plugin => {
      try {
        const conf: PluginConf = require(`./${plugin}/${plugin}`).default;
        const _plugin = plugins[conf.pluginName];
        if (_plugin) {
          const msg = [
            `Could not register plugin .`,
            `'${conf.pluginName}' is already bound.`
          ].join(' ');
          throw new Error(msg);
        }
        plugins[conf.pluginName] = conf;
        conf.registerPlugin();
      } catch (e) {
        throw new Error(
          `[ERROR] App config specified a dependency on plugin '${plugin}'` +
            ` but no such plugin was found in the codebase. Error:\n` +
            e.toString()
        );
      }
    });
  }
}