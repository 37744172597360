/**
 * this defines the grant feature.
 * 
 * the way it works is by taking a
 * node we wish to access, pulling all
 * grants which target that node, and
 * checking whether or not the user
 * can perform the action on the node.
 * 
 * grants provide a list of actors, or
 * grantees, in the form a filter.
 * 
 * they also provide a list of targets
 * in the form a filter.
 * 
 * if a user is in the grantees filter
 * and the target is in the targets filter,
 * then the actions are permitted by user
 * on the target resource.
 * 
 * time complexity for this behavior is 
 * potentially extremely high because i opted to use
 * filters to define the target sets, grantee sets,
 * and grant sets via filters instead of static
 * lists. 
 * 
 * however, that is not to say that the 
 * implementor of an app could not define
 * target sets, grantee sets, or grant sets
 * internally via a set filter. this would
 * mean the check on any given node would be
 * a product of the size of the grant set
 * and the largest of either the grantee
 * set or the target set for each grant.
 * 
 * however, should each filter be defined
 * as some filter that actually must check 
 * each node, then time complexity will be 
 * n^2 where n is the total number of nodes. 
 * 
 * this is because the grant list, the grantees 
 * for each grant, and the targets for each grant 
 * are computed from filtering upon the entire data set
 * like i said, this is horribly inefficient. but 
 * it enables the app behavior i desire. 
 * 
 * i doubt i will ever be able to design this efficiently.
 * but as this is an experimental and practically 
 * useless application without scale, i am going to 
 * keep the behavior until it becomes a problem
 */

import { get } from '@prmichaelsen/ts-utils';
import { Hook, HookFn, HookFnResult, ValidationResult } from '../../core/hooks/hooks.interface';
import { registerViewHook, ViewHookFn } from '../../core/hooks/view-hook-manager';
import { NodeEntity } from '../../Node/NodeDefinition';
import { storage } from '../../storage';
import { PluginConf } from '../plugins';
import { getNodes } from './grants.utils';
import { GrantNodeTypeData, grantPluginName, grantPluginVersion } from './grants.interface';
import { preCreateHookManager } from '../../core/hooks/pre-create-hook-manager';
import { preUpdateHookManager } from '../../core/hooks/pre-update-hook-manager';

const getById = (id: string, nodes: Array<NodeEntity>) => nodes.find(n => n.id === id);

/** 
 * given a target resource, this iterates over _every_
 * grant in the db and then checks _every_
 * grant's targets to see if the grant 
 * includes the target.
 * 
 * this is _horribly_ resource inefficient,
 * but i really do not care, bc it enables the
 * app behavior i want.
 */
const findGrantsByTarget = (target: string, nodes: Array<NodeEntity>): NodeEntity<GrantNodeTypeData>[] => {
  // todo: does a hardcoded filter by type make sense here or
  // does a filter id make sense?
  const grantNodes = nodes
    .filter((n: any): n is NodeEntity<GrantNodeTypeData> => n !== undefined && n.type === grantPluginName)
  ;
  // TODO fix 'any'. Grant should store it's
  // properties on a 'data' property
  return grantNodes.filter(grant => {
    if (!grant.data) {
      return false;
    }
    const curTargets = getNodes(grant.data[grantPluginName].target, nodes);
    return curTargets.some(n => n.id === target);
  });
}

/* can grantee perform action on target? */
interface IsGrantedParams {
  /** id of grantee */
  grantee: string,
  /** action to check */
  action: string,
  /** item being checked */
  target: string,
  /** nodes to search */
  nodes: Array<NodeEntity>,
}
/**
 * can grantee perform action on target?
 */
export const isGranted = (params: IsGrantedParams): boolean => {
  const { nodes } = params;
  const grantee = getById(params.grantee, nodes);
  if (!grantee)
    return false;
  const target = getById(params.target, nodes);
  if (!target)
    return false;

  // we need to find a grant
  // which permits 'action'
  // on target.

  // first, we need all grants
  // grantee has for this target.

  // we get grants applicable to the target
  const grants = findGrantsByTarget(target.id, nodes);

  // then filter for the grants
  // granted to the grantee 
  const applicableGrants = grants.filter(grant => {
    if (!grant.data) {
      return false;
    }
    const grantees = getNodes(grant.data[grantPluginName].grantee, nodes);
    return grantees.some(
      n => n.id === grantee.id
    );
  });

  // then, we check if action is permitted
  // by any of those grants
  return applicableGrants.some(grant => {
    if (!grant.data) {
      return false;
    }
    return grant.data[grantPluginName].actions.some(action => action === params.action)
  });

  // we need to check if the grantor
  // for this grant still can grant this action

  // that is, say patrick grants view. sweet.
  // does patrick have permission to grant view?

  // isGranted({
  //   grantee: 'patrick',
  //   action: 'grant-view',
  //   target,
  // });

  /**
   * currently, i dont think there's anyway to support
   * this. here's why:
   * 
   * if admin grants: view, grant-view to patrick,
   * then patrick can view and grant others to view.
   * however, what if patrick wants to grant someone 
   * else grant-view? he can't, because he doesn't have
   * grant-grant-view. 
   * 
   * i feel like i have three problems:
   * - can i do thing
   * - can i let others do thing
   * - can i let others do thing let others do thing
   * 
   * so maybe this is more of a 
   * permission manager issue?
   * 
   * if patrick can manage permissions,
   * then he should be able to grant others view.
   * if i grant someone else permission to manage
   * permissions, he should not necessarily be able
   * to grant other permissions to manage permissions as well.
   * 
   * ill need to think about this some more.
   * in the mean time, im committing the current 
   * "grant" spec
   */
}

export const grantViewHookFn: ViewHookFn = (node, nodes) => {
  const action = 'view';
  const props = get(
    node,
    (o) => o.metadata,
    (o) => o[grantPluginName],
    (o) => o[action],
  );

  const userId = storage.userId();

  if (!props) {
    // if metadata.view === undefined,
    // then permit view.
    // this is for backwards compatability
    // with nodes that do not have
    // grant metadata.
    // this might not make sense at all
    // and i should change the way it works
    // eventually.
    return true;
  }

  if (props.some((path) => path === '*')) {
    return true;
  }

  const isOk = isGranted({
    grantee: userId,
    action,
    target: node.id,
    nodes,
  });

  return isOk;
};

export const grantViewHook: Hook<ViewHookFn> = {
  hookName: grantPluginName + '.view',
  hookFn: grantViewHookFn,
}

export const grantEditHookFn = (node, nodes): HookFnResult => {
  const action = 'edit';
  const props = get(
    node,
    (o) => o.metadata,
    (o) => o[grantPluginName],
    (o) => o[action],
  );

  const userId = storage.userId();

  if (!props) {
    // if metadata.edit === undefined,
    // then we still need to check permissions.

    // hack: this is a cheat code to
    // allow users to edit their posts
    // until i refactor grants to be more
    // performant.
    // grants work fine on hexd-dev
    // because there are so few nodes.
    // it blows up the application on rixfeed
    // because we have over 2,000 nodes there
    return {
      pass: true,
      code: '',
      message: 'userId matches creatorId',
      property: 'n',
    }
  }
  if (props.some((path) => path === '*')) {
    return {
      pass: true,
      code: '',
      message: 'some path granted *',
      property: 'n',
    }
  }

  const isOk = isGranted({
    grantee: userId,
    action,
    target: node.id,
    nodes,
  });

  return {
    pass: isOk,
    code: '',
    message: 'isGranted returned true.',
    property: '',
  }
};

export const grantEditHook: Hook<HookFn> = {
  hookName: grantPluginName + '.edit',
  hookFn: grantEditHookFn,
}

export const grantPreCreateHookFn = (node, nodes): HookFnResult => {
  const name = 'grants.pre-create-hook-fn'
  // this hook only cares about
  // attempts to create/update grant
  // nodes
  if (node.type !== grantPluginName) {
      return {
        pass: true,
        code: '',
        message: 'Node is not type=\'grant\'.',
        property: '',
      }
  }
  // else -> type === grant
  const grantNode = node as NodeEntity<GrantNodeTypeData>;
  // admin can do anything
  const userId = storage.userId();
  if (userId === 'admin') {
    return {
      pass: true,
      code: '',
      message: 'User is admin.',
      property: '',
    }
  }
  if (!grantNode.data) {
    return {
      pass: true,
      code: '',
      message: 'No grantNode.data',
      property: '',
    }
  }
  // check if user has 'grant' permission
  // for target. 'grant' currently enables
  // granting any action. in the future,
  // grant-view, grant-update, etc may be
  // required.
  const isOk = isGranted({
    grantee: userId,
    action: 'grant',
    target: grantNode.data[grantPluginName].target,
    nodes
  });

  return {
    pass: isOk,
    code: '',
    message: 'isGranted returned true.',
    property: '',
  }
};

export const grantPreCreateHook: Hook<HookFn> = {
  hookName: grantPluginName,
  hookFn: grantPreCreateHookFn,
}

const grantPlugin: PluginConf = {
  pluginName: grantPluginName,
  pluginVersion: grantPluginVersion,
  registerPlugin: () => {
    registerViewHook(grantViewHook);
    preCreateHookManager.registerHook(grantPreCreateHook);
    preUpdateHookManager.registerHook(grantEditHook);
  },
}

export default grantPlugin;