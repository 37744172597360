import { registerCommand } from "../../core/commands/commands";
import { registerFilterOperator } from "../../filter/filter-operators";
import { PluginConf } from "../plugins";
import { createSetCommand } from "./commands/create-set";
import { excludeSetFilterOperator } from "./sets.exclude-operator";
import { includeSetFilterOperator } from "./sets.includes-operator";
import { setsPluginName, setsPluginVersion } from "./sets.interface";

const setsPlugin: PluginConf = {
  pluginName: setsPluginName,
  pluginVersion: setsPluginVersion,
  registerPlugin: () => {
    registerFilterOperator(includeSetFilterOperator);
    registerFilterOperator(excludeSetFilterOperator);
    registerCommand(createSetCommand);
  },
}

export default setsPlugin;