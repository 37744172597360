import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useField } from '@parm/react/use-field';
import { useStyles } from './hooks/useStyles';
import { NodeContext } from './NodeContext';
import { useData, useNode } from './firebase';
import { debounce } from '@parm/util';
import { get } from '@prmichaelsen/ts-utils';
import { Setting, Settings } from './settings.interface';

export const useSettings = (): Settings => {
  const adminSettingsNode = useNode('admin.settings');
  const settings = get(adminSettingsNode,
    o => o.data,
    o => o.settings
  );
  return settings || {};
}

interface SettingEditorProps {
  id: string,
  setting: Setting,
  route: string[],
}

const SettingEditor = (props: SettingEditorProps) => {
  const { 
    setting, id, route: routeArr 
  } = props;
  const classes = useStyles();
  const { updateNode } = useData();
  const { value, field } = useField({
    label: setting.title,
    value: setting.value,
    multiline: setting.multiline === true,
  });
  const route = routeArr.join('/');
  const path = setting.path.join('.');
  const qualifiedPath = [route, path].join('/');
  useEffect(debounce({ wait: 500, func: () => {
    updateNode({
      id,
      data: {
        settings: {
          [path]: {
            ...setting,
            value,
          },
        }
      }
    });
  }}), [value]);
  return (
    <div key={qualifiedPath}>
      <h4>
        {path}
      </h4>
      <div>
        {field}
      </div>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.quote}
        component="div"
      >
        {setting.description}
      </Typography>
    </div>
  );
}

export const SettingsEditor = () => {
  return (
    <NodeContext.Consumer>
      {context => {
        const node = context.node;
        if (!node) {
          return (<></>);
        }
        const data = node.data;
        if (!data) {
          return (
            <span>
              Error: Settings node is missing `data` property.
            </span>
          );
        }
        if (!data.route) {
          return (
            <span>
              Error: Settings node is missing `data.route` property.
            </span>
          );
        }
        const settings = data.settings;
        const route = data.route.join('/');

        return Object.keys(settings)
          .map(settingKey => settings[settingKey])
          .map(setting => {
            const path = setting.path.join('.');
            const qualifiedPath = [route, path].join('/');
            return (
              <SettingEditor 
                key={qualifiedPath}
                id={node.id}
                route={data.route}
                setting={setting}
              />
          )});
      }}
    </NodeContext.Consumer>
  );
};