import React, { useCallback, useState } from 'react';

import {
  Card, CardActions, CardContent, Grid
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import CodeIcon from '@material-ui/icons/Code';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import LinkIcon from '@material-ui/icons/Link';
import PostAddIcon from '@material-ui/icons/PostAdd';
import DataObjectIcon from '@material-ui/icons/AccessAlarmSharp';
import { debounce } from '@parm/util';
import clsx from 'clsx';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import { AddToSet } from './plugins/sets/AddToSet';
import { NodeEditor } from './components/NodeEditor';
import { SignInGate } from './components/SignInGate';
import { preUpdateHookManager } from './core/hooks/pre-update-hook-manager';
import { EmailRegister } from './plugins/users/components/EmailRegister';
import { useData, useMeta, useNode, useRoles } from './firebase';
import { LoadingSpinner } from './LoadingSpinner';
import { Markdown } from './Markdown';
import { NodeCreateProps, NodeEntity, NodeUpdateProps } from './Node/NodeDefinition';
import { storage } from './storage';
import { useStyles } from './hooks/useStyles';
import { useUserId } from './hooks/useUserId';
import { NodeContext } from './NodeContext';


/**
 * strips a lot of row properties
 * that shouldn't be on a node off
 * of the node
 */
const sanitizeNode = (row: Row) => ({
  id: row.id,
  children: row.children || [],
  text: row.text,
  parent: row.parent,
  type: row.type,
  ...(row.data ? { data: row.data } : {}),
  ...(row.metadata ? { metadata: row.metadata } : {}),
  ...({
    creatorId: row.creatorId,
    isRoot: row.isRoot,
    createTime: row.createTime,
    ...(row.updateTime ? { updateTime: row.updateTime } : {}),
  } as any),
} as NodeEntity);

type Row = NodeCreateProps & Partial<NodeUpdateProps> & {
  /** row properties, aka
   * props added by Adventure */
  new?: boolean,
  prev?: boolean,
  root?: boolean,
  showBackButton?: boolean,
  createOption: (props: NodeCreateProps) => Promise<void>,
}
export const AdventureOptionCard = (row: Row | null) => {
  if (!row) {
    return (
      <LoadingSpinner/>
    );
  }
  const roles = useRoles();
  const { state: { nodes }} = useData();
  const node = row.id && useNode(row.id) || sanitizeNode(row);
  const [userId] = useUserId()
  const isAuthor = userId === row.creatorId;
  const isAdmin = roles.includes('admin');
  const canEdit = 
    isAuthor 
    || isAdmin
    || preUpdateHookManager.checkHooks(row as any, nodes)
  ;
  const classes = useStyles();

  /** editing data shows the NodeEditor
   * allowing user to edit the node
   * json document directly.
   */
  const [isEditingData, setIsEditingData] = useState(false);
  const handleToggleEditingData = () => {
    setIsEditingData(!isEditingData);
  }

  const [isViewingSource, setIsViewingSource] = useState(false);
  const handleToggleViewSource = () => {
    setIsViewingSource(!isViewingSource);
  }

  const [isAddingToSet, setIsAddingToSet] = useState(false);
  const handleToggleAddingToSet = () => {
    setIsAddingToSet(!isAddingToSet);
  }

  const setDraft = (_text: string) => {
    if (!row.new)
      return;
    const func = () => storage.setDraft(_text);
    const debouncedFn = debounce({
      func,
      wait: 700,
    })
    debouncedFn();
  };

  const [isEditing, setEditing] = useState(row.new || false);
  const handleToggleEdit = () => {
    if (isEditing) {
        row.createOption({
          text: text.trim(),
          id: row.id,
          parent: row.parent,
          type: row.type,
          creatorId: row.creatorId,
        });
        setDraft('');
        setEditing(false);
    } else {
      setText(rowText);
      setDraft(rowText);
      setEditing(true);
    }
  }
  const handleCopy = () => {
    navigator.clipboard.writeText(rowText);
  }

  const rowText = node ? node.text : '';
  const [text, setText] = useState(row.new ? storage.draft() : '');
  const handleChange = (event) => {
    const _text = event.target.value;
    setText(_text);
    setDraft(_text);
  };

  const [hasBlurred, setBlurred] = useState(false);
  const onBlur = () => setBlurred(true);
  const url = `/nodes/${row.id}`;
  return (
    <Card className={classes.card} id={row.id}>
      <LazyLoad
        className={classes.avatar}
        once
        placeholder={<LoadingSpinner />}
      >
          {isEditingData && (
            <>
              <CardContent>
                <NodeContext.Provider value={{node}}>
                  <NodeEditor useYaml/>
                </NodeContext.Provider>
              </CardContent>
              <CardActions disableSpacing>
                <Grid container direction="row-reverse">
                  <Grid item>
                    <IconButton 
                      aria-label="finish editing data"
                      onClick={handleToggleEditingData}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardActions>
            </>
          )

          || isViewingSource && (
            <>
              <CardContent>
                <TextField
                  style={{ width: '100%' }}
                  label={'Source text'}
                  multiline
                  value={rowText || ''}
                />
              </CardContent>
              <CardActions disableSpacing>
                <Grid container direction="row-reverse">
                  <Grid item>
                    <IconButton 
                      aria-label="view node"
                      onClick={handleToggleViewSource}
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardActions>
            </>
          )

          || isAddingToSet && (
            <>
              <CardContent>
                <AddToSet
                  defaultIds={row.id}
                ></AddToSet>
              </CardContent>
              <CardActions disableSpacing>
                <Grid container direction="row-reverse">
                  <Grid item>
                    <IconButton 
                      aria-label="finish"
                      onClick={handleToggleAddingToSet}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardActions>
            </>
          )

          || isEditing && (
            <SignInGate
              gate="create"
              SignInComponent={EmailRegister}
              message="sign in to create your own posts"
              isCard
            >
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      id="new-option-input"
                      label={row.type === 'prompt' ? 'Divinate' : 'Improvise'}
                      placeholder="Type your dialogue or actions here..."
                      multiline
                      value={text}
                      onChange={handleChange}
                      onBlur={onBlur}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions disableSpacing>
                <Grid container direction="row-reverse">
                  <Grid item>
                    <IconButton 
                      aria-label="finish edit"
                      onClick={handleToggleEdit}
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </CardActions>
            </SignInGate>
          )

          || (
            <>
              <CardActions disableSpacing>
                <Grid container direction="row-reverse">
                  {/* <Grid item>
                          <IconButton aria-label="share" className={classes.action}>
                            <ShareIcon />
                          </IconButton>
                        </Grid> */}
                  <Grid item>
                    <IconButton
                      aria-label="edit node data"
                      onClick={handleToggleEditingData}
                    >
                      <DataObjectIcon/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="add to set"
                      onClick={handleToggleAddingToSet}
                    >
                      <PostAddIcon/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="view node source"
                      onClick={handleToggleViewSource}
                    >
                      <CodeIcon/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="copy node source"
                      onClick={handleCopy}
                    >
                      <AssignmentIcon/>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <>
                    <Link to={url}>
                      <IconButton
                        aria-label="set focus"
                      >
                        <LinkIcon />
                      </IconButton>
                    </Link>
                    </>
                  </Grid>
                  {canEdit && (
                    <Grid item>
                      <IconButton
                        aria-label={'edit'}
                        onClick={handleToggleEdit}
                        className={clsx({
                          [classes.active]: isEditing,
                        })}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </CardActions>
              <CardContent>
                <Markdown node={node}>
                  {rowText || ''}
                </Markdown>
              </CardContent>
            </>
          )}
      </LazyLoad>
    </Card>
  );
}