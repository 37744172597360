export const grantFeatureSet = 'f5.core.grants';
export const grantPluginName = 'parm.grants';
export const grantPluginVersion = 'v1.0.0';

/**
 * this is data that must exist
 * on any node that defines its
 * type as [pluginName]
 */
export interface GrantNodeTypeData {
  [grantPluginName]: {
    version: string;
    /** a node id */
    grantor: string,
    /** 
     * an id to the grantee. 
     * a grantee is either a node,
     * a filter, or a user
     */
    grantee: string,
    /** a set of permitted actions */
    actions: string[],
    /** 
     * an id to a target. 
     * a target is either a node,
     * a filter, or a user
     */
    target: string,
  }
}

/**
 * this is metadata that may exist
 * for any node of any type
 * if this plugin is registered
 * for use by the application.
 */
export interface GrantNodeMetadata {
  [grantPluginName]?: {
    version: string;
    /**
     * a list of dot delimited paths
     * of visible properties of this node
     * 
     * note: the absence of this property
     * implies the node is visible.
     * 
     * undefined -> node is not visible
     * [] -> node is not visible
     * ['id'] -> node is visible, id is visible
     * ['id', 'text'] -> node is visible, id and text visible
     * ['*'] -> all properties visible
     * ['data.*'] -> all data properties visible
     */
    view?: string[],
    /**
     * same as above, but for editing
     * the node or properties of it
     */
    edit?: string[],
  }
}