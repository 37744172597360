import React, { useCallback, useEffect, useState } from 'react';
import uuidv1 from 'uuid/v1';
import { get } from '@prmichaelsen/ts-utils';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui'
import { Grid, Button } from '@material-ui/core';
import { fetchNode } from '../../../firebase';
import { createUserCommand } from '../commands/create-user';

// Initialize the FirebaseUI Widget using Firebase.
const ui = new firebaseui.auth.AuthUI(firebase.auth());


interface EmailRegisterProps {
}

export const EmailRegister = (props: EmailRegisterProps) => {

  const [id] = useState(
    'firebaseui-auth-container-' + uuidv1()
  );

  const auth = firebase.auth();

  const [user, setUser] = useState(get(auth.currentUser, u => u.email));

  useEffect(() => {
    if (user)
      return;
    ui.start(`#${id}`, {
      popupMode: true,
      callbacks: {
        signInSuccessWithAuthResult: () => {
          const email = get(auth.currentUser, u => u.email);
          setUser(email);
          const innerAsync = async () => {
            const node = await fetchNode(`users.${email}`);
            if (!node) {
              createUserCommand.execute({
                userId: `users.${email}`, 
                email,
              });
            }
          }
          innerAsync();
          return false;
        }
      },
      signInOptions: [
        {
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
          requireDisplayName: false
        }
      ]
    });
  }, [user]);

  const onSignOut = useCallback(async () => {
    await auth.signOut();
    ui.reset();
    setUser(null);
  }, [user]);

  if (user) {
    return (
      <div>
        {`signed in as ${user}`}

        <Grid container direction="row-reverse">
          <Grid item>
            <Button
              aria-label={'sign out'}
              onClick={onSignOut}
            >
              sign out
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div id={id}/>
  );
}