import { get } from "@prmichaelsen/ts-utils";
import { NodeEntity } from "../../Node/NodeDefinition";
import { setsPluginName } from "./sets.interface";

export const getSetByName = (_nodes, name): (NodeEntity | undefined) => 
  _nodes.find(node => get(
    node,
    o => o.data,
    o => o[setsPluginName],
    o => o.name
  ) === name);

export const getSet = (setName: string, nodes: Array<NodeEntity>) => {
  const setNode = getSetByName(nodes, setName);
  if (setNode) {
    const setIds: string[] = get(
      setNode, 
      o => o.data,
      o=> o[setsPluginName],
      o => o.ids
    ) || [];
    return new Set(setIds);
  }
  return new Set();
}

export const getSetById = (id: string, nodes: Array<NodeEntity>) => {
  const setNode = nodes.find(n => n.id == id);
  if (setNode) {
    const setIds: string[] = get(
      setNode, 
      o => o.data,
      o=> o[setsPluginName],
      o => o.ids
    ) || [];
    return new Set(setIds);
  }
  return new Set();
}