import { Grid, Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useForm } from '../../extern/useForm';
import { CreateSetCommandOptions, CreateSetParams } from '../../plugins/sets/commands/create-set';
import { CommandDefinition, commands } from './commands';

interface CommandProps {
  commandName: string,
}

export const Command = (props: CommandProps) => {
  const { commandName } = props;
  const commandDef: 
    CommandDefinition<CreateSetCommandOptions> = 
      commands[commandName];

  if (!commandDef) {
    return (
      <>
        {commandName} not found.
      </>
    );
  }

  const { options } = commandDef;
  const form = useForm({ options })

  const onSubmit = useCallback(() => {
    const params: CreateSetParams = 
      form.reduce((acc, data) => {
      acc[data.key] = data.value;
      return acc;
    }, {} as any)
    commandDef.execute(params)  
  }, [...form.map(option => option.value)]);

  return (
      <>
      <h2>command executor</h2>
      <h3>
        {commandName.split('.').slice(1).join(' > ')}
      </h3>
      {commandDef.description}
      {form.map(option => option.field)}
      <Grid container direction="row-reverse">
        <Grid item>
          <Button
            aria-label={'submit'}
            onClick={onSubmit}
          >
            submit
          </Button>
        </Grid>
      </Grid>
    </>
  )
}