// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { Environment } from './environment.interface';

export const environment: Environment = {
  "metaDescription": "the back door to the internet",
  "header": "rick's feed",
  "database": "parm",
  "metaTitle": "rick's feed",
  "archetype": "blog",
  "stage": "prod",
  "plugins": [
    "grants",
    "sets"
  ],
  "app": "parm",
  "title": "rick's feed",
  "maxResponses": 1,
  "numResponses": 1,
  "host": "parm-parm",
  "version": "0.0.0"
};