import { FilterOperator } from "../../filter/filter-operators";
import { setsExcludeOperator } from "./sets.interface";
import { getSetById } from "./sets.utils";

/**
 * this filter excludes any nodes
 * that exist within the specified 
 * set (by set name)
 * 
 * left: set name
 * right: null
 */
export const excludeSetFilterOperator: FilterOperator<any, any> = {
  operator: setsExcludeOperator,
  filterFnFactory: (node, nodes) => (setId, r) => {
    const set = getSetById(setId, nodes);
    return !set.has(node.id);
  }
}