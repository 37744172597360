import Axios from 'axios';
import { firebase as firebaseSecrets } from '@parm/util';
import { environment } from '../environments/environment';

// Add the Firebase services that you want to use
// note: this imports must be upgraded eventually
// https://www.npmjs.com/package/firebase#user-content-compat-packages
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

// only dev uses separate data collections.
// prod and qa share their data collections.
// const env = environment.stage === 'dev' ? 'dev' : null;
const env = null;
const app = environment.app;
const database = environment.database || 'default';
const Node = env ? `${env}.${app}` : app;
const NodeMeta = `${app}.meta`;
const Roles = `${app}.roles`;

// Initialize Cloud Firestore through Firebase
// Initialize Firebase
firebase.initializeApp(firebaseSecrets[database]);

var db = firebase.firestore();

/** temp dev origin */
const apiUri = 'https://us-central1-parm-app.cloudfunctions.net';
// const apiUri = 'http://localhost:8080';

const ImagesStore = `${app}/images`;
console.log({
  env, app, Node, NodeMeta
});

import { useState, useEffect } from 'react';
import { storage } from './storage';
import uuidv1 from 'uuid/v1';
import { getImageUrl, log } from './utils';
import { useFilter } from '@parm/react/filter-control';
import { NodeCreateProps, NodeEntity, NodeUpdateProps } from './Node/NodeDefinition';
import { viewHookFilter } from './core/hooks/view-hook-manager';
import { preCreateHookManager } from './core/hooks/pre-create-hook-manager';
import { preUpdateHookManager } from './core/hooks/pre-update-hook-manager';
import { useNavigate } from 'react-router';

export interface RoleDocument {
  roles: string[];
}


interface State {
  nodes: NodeEntity[],
  prev: NodeEntity[],
  root: NodeEntity | null,
  current: string | null,
  stateId: string,
}

const initialState: State = {
  nodes: [],
  prev: [],
  root: null,
  current: null,
  stateId: uuidv1(),
}; 

export async function createNode(
  node: NodeCreateProps,
  nodes: NodeEntity[] = [],
  prev: NodeEntity[] = [],
  isRoot: boolean = false,
) {
  const {
    text, type, parent, id, data,
  } = node;
  let { creatorId } = node;
  const createTime = firebase.firestore.Timestamp.fromDate(new Date());
  const userId = storage.userId();
  if (creatorId === undefined) {
    creatorId = userId;
  }
  /**
   * if some node was quoted, use it as the
   * parent. for now, I only support one
   * parent per node. this is liable to change
   * in the future.
   * 
   * as of 2021-11-23, node updates made after 
   * the initial creation of a node will not 
   * update the parent-child relationship.
   * this behavior is liable to change, so check
   * the update code to verify accuracy of this
   * qualification and update this comment 
   * accordingly.
   */
  const quoteParent = findQuotedNode(text);
  const optionDto = {
    creatorId,
    createTime,
    text,
    children: [],
    parent: quoteParent || parent,
    type,
    isRoot,
  };
  if (data) 
    (optionDto as any).data = data;
  let option: NodeEntity;
  if (id) {
    await db.collection(Node).doc(id).set({
      ...optionDto,
      id,
    });
    const optionSnapshot = await db.collection(Node).doc(id).get();
    option = optionSnapshot.data() as any;
  } else {
    const optionRef = await db.collection(Node).add(optionDto);
    const optionSnapshot = await optionRef.get();
    option = optionSnapshot.data() as any;
    option.id = optionRef.id;
    // update the document with its id
    await optionRef.set({
      ...option,
    });
  }
  let parentNode = nodes.find(n => n.id === parent);
  if (parentNode) {
    parentNode.children.push(option.id);
    await db.collection(Node).doc(parentNode.id).update(parentNode);
  }
  const root = nodes.find(n => n.isRoot);
  return {
    root,
    nodes: [...nodes, option],
    current: option.id,
    prev: [...prev, option],
    option,
  }
}

interface MapNodeParams {
  /** force allow viewership */
  view?: boolean,
}
const mapNodes = (baseNodes: Array<NodeEntity>, params: MapNodeParams = {}) => {
  const { view } = params;
  const filteredNodes = view ? baseNodes : viewHookFilter(baseNodes);
  const nodes = filteredNodes;

  return nodes.map(n => {
    return n;
  });
}

const fetch = async () => {
  const e = await db.collection(Node).get();
  const nodes: NodeEntity[] = e.docs.map(d => ({
    id: d.id,
    ...d.data(),
    // firebase encodes \n as \\n
    text: d.data().text
      .replace(/\\\n/g, '\n')
      .replace(/\\\t/g, '\t')
  })) as any;
  return {
    nodes: mapNodes(nodes),
    root: nodes.find(n => n.isRoot),
  }
}

interface NodeMeta {
  /**
   * user ids of those who liked it
   */
  likes: string[],
  /**
   * user ids of those who visited this node once or more
   */
  visited: string[],
  /**
   * num times this node's route was loaded
   */
  views: number,
}

const initialNodeMeta: NodeMeta = {
  likes: [],
  visited: [],
  views: 0,
}

export function useImages(limit: number = 1000) {
  const [pageToken, setPageToken] = useState(null as string | null);
  const [urls, setUrls] = useState([] as string[]);
  const ref = firebase.storage().ref(ImagesStore);
  const nextPage = () => {
    ref.list({
      maxResults: limit,
      pageToken: pageToken,
    }).then(async (result) => {
      setPageToken(result.nextPageToken);
      setUrls(await Promise.all(result.items.map(async i => {
        return getImageUrl({filename: i.name});
      })));
    }).catch((e) => { throw new (e); });
  }
  if (pageToken === null)
    nextPage();
  return {
    nextPage,
    urls,
  }
}

export function useImageUpload() {
  const uploadImage = async (file: File) => {
    const uuid = uuidv1();
    const ext = file.type.split('/')[1];
    const id = `${ImagesStore}/${uuid}.${ext}`;
    const ref = firebase.storage().ref(id);
    const metadata = {
      contentType: file.type,
    }
    return await ref.put(file, metadata);
  }

  return {
    uploadImage,
  };
}

/**
 * reference https://reacttraining.com/blog/react-router-v5-1/
 * @deprecated currently deprecated because useMeta
 * was driving up daily reads into quota limits. The view 
 * counting was broken anyway. Metadata should be a part of
 * the primary document to avoid two document reads for
 * one logical set of data (more cost effective).
 */
export function useNodeView(nodeId: string) {
  // just return dummy meta.
  // this isn't persisted anywhere.
  return {
    views: 0,
  }
}

export function useRoles() {
  const [roles, setRoles] = useState<string[]>([]);
  useEffect(() => {
    const userId = storage.userId();
    const unsub = db.collection(Roles).doc(userId).onSnapshot(e => {
      const doc = e.data();
      if (!doc)
        return;
      setRoles(doc.roles || []);
    });
    return unsub;
  });
  return roles;
}

/*
 * @deprecated currently deprecated because useMeta
 * was driving up daily reads into quota limits. The view 
 * counting was broken anyway. Metadata should be a part of
 * the primary document to avoid two document reads for
 * one logical set of data (more cost effective).
 */
export function useMeta(nodeId: string) {
  const [meta, setMeta] = useState({...initialNodeMeta});
  // just return dummy meta or set meta locally.
  // this isn't persisted anywhere.
  return {
    meta,
    setMeta: (meta: NodeMeta) => 
      setMeta({ ...meta })
    ,
  };
}

export const fetchNode = async (nodeId: string): Promise<NodeEntity | undefined> => {
    const optionSnapshot = await db.collection(Node).doc(nodeId).get();
    const data = optionSnapshot.data();
    if (!data) {
      return undefined;
    }
    const nodeRaw: NodeEntity = optionSnapshot.data() as any;
    const node: NodeEntity = {
      ...nodeRaw,
      // firebase encodes \n as \\n
      text: nodeRaw.text
        .replace(/\\\n/g, '\n')
        .replace(/\\\t/g, '\t'),
    };
    return node;
}


export function useNode(nodeId: string): NodeEntity | undefined {
  const [state, setState] = useState<NodeEntity | undefined>(undefined);
  useEffect(() => {
    if (!nodeId) {
      return;
    }
    const unsub = db.collection(Node)
      .doc(nodeId)
      .onSnapshot(ref => {
        const document = ref.data();
        if (!document)
          return undefined;
        const nodeRaw: NodeEntity = document as any;
        const node: NodeEntity = {
          ...nodeRaw,
          // firebase encodes \n as \\n
          text: nodeRaw.text
            .replace(/\\\n/g, '\n')
            .replace(/\\\t/g, '\t'),
        };
        setState(mapNodes([node], { view: true })[0]);
        return;
      });
    return unsub;
  }, [nodeId]);
  return state;
}

/**
 * finds first match of pattern 
 * `>>some-id` and returns `some-id`
 */
const findQuotedNode = (text: string) => {
  const regex = /\>\>(\S+)/g
  const matches = Array.from(text.matchAll(regex));
  /** first el in match is full matched exp
   * second el is first capture group */
  const arr = matches.map(([_, match]) => match);
  const matchText = arr[0];
  return matchText || null;
}

const emailHook = async (node: NodeEntity) => {
  /** email mention regex */
  const regex = /\@(.+\@.+\.\w+)/g
  const matches = node.text.matchAll(regex);
  console.log({ matches });
  Array.from(matches)
    .map(([_, match]) => {
      console.log({match});
      const parts = [
`hi ${match}!`,

`you were mentioned in 
<a href="${window.origin}/nodes/${node.id}">#${node.id}</a>
on ${environment.header}. check out the post to see 
what they said.`,

`sincerely,`,

`<a href="mailto:rick@ricksfeed.app">rick@ricksfeed.app</a>`,
      ];
      const html = parts.join('<p></p>');
      const text = parts.join('\n');
      const subject = `${environment.header}: new mention for ${match}! (#${node.id.substr(0,4)})`;
      return {
        to: match,
        from: 'rick@ricksfeed.app',
        subject,
        text,
        html,
      };
    })
    /** fire and forget, do not block ui thread */
    .forEach(async data => {
      console.log({ data });
      await Axios.post(
        `${apiUri}/functionSendEmail`,
        data,
        {
          headers: {
            // 'Access-Control-Allow-Origin': 'http://localhost:4203',
            'Access-Control-Allow-Origin': 'https://ricksfeed.app',
            'Content-Type': 'application/json'
          },
        },
      )
    });
  ;
}

const hooks: Array<(node: NodeEntity) => Promise<void>> = [
  emailHook,
];

const globalState = {
  state: { ...initialState },
}
export function useData() {
  const navigate = useNavigate();
  const [state, updateState] = useState(globalState.state);
  const setState = (state: State) => {
    globalState.state = {...state};
    updateState(state);
  };
  const setCurrent = (current: string) => {
    const someNode = [state.nodes.find(n => n.id === current)].filter(
      (n: any): n is NodeEntity => n !== undefined
    );
    setState({
        ...state,
        current,
        prev: [...state.prev, ...someNode],
    });
  };
  async function updateNode(props: NodeUpdateProps) {
    const updateTime = firebase.firestore.Timestamp.fromDate(new Date());
    const result = preUpdateHookManager.checkHooks(props as any, state.nodes);
    if (result.error.length > 0) {
      const failedHooks = result.error.map(r => `[ERROR] hook='${r.hookName}' failed`);
      throw new Error([
        `checkHooks='${result.hookType}' failed.`,
        ...failedHooks,
      ].join('\n'));
    }
    const {
      id, data, text, 
      createTime: _, creatorId: __, 
      ...rest
    } = props as any;
    await db.collection(Node).doc(id).set({
      ...rest,
      ...(data ? { data } : {}),
      ...(text ? { text } : {}),
      updateTime,
      id,
    }, { merge: true });
    const { nodes, root = null } = await fetch();
    setState({
        ...state,
        root,
        nodes,
        stateId: uuidv1(),
    });
  };
  async function createOption(props: NodeCreateProps) {
    const result = preCreateHookManager.checkHooks(props as any, state.nodes);
    if (result.error.length > 0) {
      const failedHooks = result.error.map(r => `[ERROR] hook='${r.hookName}' failed`);
      throw new Error([
        `checkHooks='${result.hookType}' failed.`,
        ...failedHooks,
      ].join('\n'));
    }
    const { text, parent, type, data, id } = props;
    const {
      root = null, nodes, option,
    } = await createNode(
      { id, text, parent, type, data},
      state.nodes,
      state.prev,
      state.nodes.length === 0 && state.prev.length === 0,
    );
    await Promise.all(hooks.map(async hook => await hook(option)));
    setState({
        root,
        nodes,
        current: option.id,
        prev: [...state.prev, option],
        stateId: uuidv1(),
    });
    navigate(`/nodes/${option.id}`);
  }
  useEffect(() => {
    fetch().then(({nodes, root = null}) => setState((() => {
      const s = {
        ...state,
        nodes: mapNodes(nodes),
        root,
      }
      return s;
      })()));
  }, [state.stateId]);
  const { 
    filter: filterPredicate,
    control: filterControl
  } = useFilter();
  return {
    filter: {
      predicate: filterPredicate,
      control: filterControl,
    },
    state,
    setCurrent,
    createOption,
    updateNode,
  }
}
