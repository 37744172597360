import React from "react";

interface Components {
  [componentName: string]: React.Factory<any>
}

export const components: Partial<Components> = {
};

/**
 * register new components that may be used.
 * this is designed in a way that new plugins
 * may define new components.
 * when a plugin is registered, it will also
 * register the new component which
 * will then be available for use in the 
 * application.
 */
export const registerComponent = 
  (component: React.Factory<any>) => {
    const value = 
      components[component.name];
    if (value) {
      const msg = [
        `Could not add component.`,
        `'${component.name}' is already bound.`,
      ].join(' ');
      throw new Error(msg);
    }
    components[component.name] = component;
  }

export const clearComponents = () => {
  Object.keys(components).forEach(key => {
    delete components[key];
  });
};