import React, { useCallback, useEffect, useState } from 'react';

import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/theme-twilight';
import { NodeContext } from '../NodeContext';
import { NodeEntity } from '../Node/NodeDefinition';
import { useData } from '../firebase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { CodeEditor } from './CodeEditor';
import yaml from 'yaml';

interface NodeEditorProps {
  useYaml?: boolean,
}

interface NodeEditorInternalProps {
  context: { node: NodeEntity }
}
const NodeEditorInternal = (props: NodeEditorInternalProps & NodeEditorProps) => {
  const { context, useYaml } = props;
  const { updateNode } = useData();
  const [content, setContent] = useState('');
  const [isContextLoaded, setContextLoaded] = useState(false);

  const onSave = useCallback(() => {
    let node;
    try {
      if (useYaml) {
        node = yaml.parse(content);
      } else {
        node = JSON.parse(content);
      }
    } catch (e) {
      // TODO better error handling
      console.log('[ERROR]: failed content parse during node update', e);
    }
    updateNode({
      ...node,
      id: context.node.id,
    });
  }, [content, (context && context.node)]);
  useEffect(() => {
    if (!isContextLoaded) {
      if (useYaml) {
        setContent(yaml.stringify(context.node));
      } else {
        setContent(JSON.stringify(context.node, null, 2));
      }
      setContextLoaded(true);
    }
  }, [context.node]);

  if (!context.node) {
    return (<></>);
  } else {
    return (
      <>
      <CodeEditor
          internalEditorProps={{
            value: content,
            onChange: setContent,
            maxLines: Infinity,
          }}
      />
      <Grid container direction="row-reverse">
        <Grid item>
          <Button 
            aria-label="save edit"
            onClick={onSave}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </>
    );
  }
}

export const NodeEditor = (props: NodeEditorProps) => {
  return (
    <NodeContext.Consumer>
      {context => <NodeEditorInternal context={context} {...props} />}
    </NodeContext.Consumer>
  );
}