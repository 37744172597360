
import { Button, CardActions, CardContent, Grid } from '@material-ui/core';
import { get } from '@prmichaelsen/ts-utils';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { environment } from '../../environments/environment';


export interface SignInGateProps {
  /** children that require auth */
  children: any,
  SignInComponent: any,
  gate: 'create' | 'uploadImage' | 'callReddit',
  message?: string,
  isCard?: boolean,
}

/**
 * only allow certain actions if there is a 
 * logged in user. the actions that require
 * login are specified by the app config (aka environment.ts)
 */
export const SignInGate = (props: SignInGateProps) => {
  const { 
    children, SignInComponent,
    gate, message, isCard,
  } = props;

  const { shouldRequireSignin } = environment;

  const auth = firebase.auth();
  const [user, setUser] = useState(get(auth.currentUser, u => u.email) || null);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(_user => {
      setUser(get(_user, o => o.email));
    });
    return unsub;
  });

  const requiresSignin = get(shouldRequireSignin, o => o[gate]);

  const isLoggedIn = user !== null;
  const isPermitted = requiresSignin ? isLoggedIn : true;

  const [isSigningIn, setIsSigningIn] = useState(false);
  const onStartSignInFlow = useCallback(() => {
    setIsSigningIn(true);
  }, []);

  if (isPermitted) {
    return (
      <>
        {children}
      </>
    );
  }

  const CardWrapper = ({children}) => {
    return (
      <>
        <CardActions/>
        <CardContent>
          {children}
        </CardContent>
      </>
    );
  }
  const DivWrapper = ({children}) => (<div>{children}</div>);
  const Outer = isCard ? CardWrapper : DivWrapper;

  if (isSigningIn) {
    return (
      <Outer>
        <SignInComponent/>
      </Outer>
    );
  }

  return (
    <Outer>
      {message || 'you must sign in to use this'}
      <Grid container direction="row-reverse">
        <Grid item>
          <Button
            aria-label={'sign in'}
            onClick={onStartSignInFlow}
          >
            sign in
          </Button>
        </Grid>
      </Grid>
    </Outer>
  );
}