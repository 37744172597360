import { registerCommand } from "../../core/commands/commands";
import { registerComponent } from "../../core/register-component";
import { PluginConf } from "../plugins";
import { createUserCommand } from "./commands/create-user";
import { EmailRegister } from "./components/EmailRegister";
import { usersPluginName, usersPluginVersion } from "./users.interface";

const usersPlugin: PluginConf = {
  pluginName: usersPluginName,
  pluginVersion: usersPluginVersion,
  registerPlugin: () => {
    registerCommand(createUserCommand);
    registerComponent(EmailRegister);
  },
}

export default usersPlugin;