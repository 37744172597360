/** regex
 * matching yyyy-md-dd
 */
const yyyymmdd = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

export const formatDate = (date: Date) => date.toISOString().split('T')[0];
export const formatTime = (date: Date) => date.toISOString();
export const formatRelative = (date: Date) => timeDifference(new Date(), date);

const timeDifference = (current: Date, previous: Date) => {

  let msPerMinute = 60 * 1000;
  let msPerHour = msPerMinute * 60;
  let msPerDay = msPerHour * 24;
  let msPerMonth = msPerDay * 30;
  let msPerYear = msPerDay * 365;
  let elapsed = +current - +previous;

  if (elapsed < msPerMinute) {
       return Math.round(elapsed/1000) + ' seconds ago';   
  } else if (elapsed < msPerHour) {
       return Math.round(elapsed/msPerMinute) + ' minutes ago';   
  } else if (elapsed < msPerDay ) {
       return Math.round(elapsed/msPerHour ) + ' hours ago';   
  } else if (elapsed < msPerMonth) {
      return Math.round(elapsed/msPerDay) + ' days ago';   
  } else if (elapsed < msPerYear) {
      return Math.round(elapsed/msPerMonth) + ' months ago';   
  } else {
      return Math.round(elapsed/msPerYear ) + ' years ago';   
  }
}

export const diffDays = (a: string, b: string) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(a);
  const secondDate = new Date(b)
  return Math.round(Math.abs((+secondDate - +firstDate) / oneDay));
}

export const validateDate = value => {
  return yyyymmdd.test(value);
}