import React from 'react';
import { Typography } from '@material-ui/core';
import { useField } from '@parm/react/use-field';
import { useStyles } from '../hooks/useStyles';

export interface Options { [name: string]: Option };

export interface Option {
  path: string[],
  title: string,
  description: string,
  multiline?: boolean,
  value: any,
}

export interface OptionEditorProps {
  option: Option,
}

export const useFormField = (props: OptionEditorProps) => {
  const {
    option 
  } = props;
  const classes = useStyles();
  const { value, field } = useField({
    label: option.title,
    value: option.value,
    multiline: option.multiline === true,
  });
  const path = option.path.join('.');
  const optionField = (
    <div key={path}>
      <h4>
        {path}
      </h4>
      <div>
        {field}
      </div>
      <Typography
        variant="body2"
        color="textSecondary"
        className={classes.quote}
        component="div"
      >
        {option.description}
      </Typography>
    </div>
  );
  return {
    field: optionField,
    value,
  }
}

export interface OptionsEditorProps {
  options: Options,
}

export const useForm = (props: OptionsEditorProps) => {
  const { options } = props;

  const editor = Object.keys(options)
    .map(key => {
      const option = options[key];
      const { field, value } = useFormField({
        option,
      })
      return {
        key,
        field,
        value,
      };
    });

  return editor;
}