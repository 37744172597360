import React from 'react';

import Adventure from './Adventure';
import { Container, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useThemePrefs } from './hooks';
import { registerPlugins } from './plugins/plugins';


registerPlugins();

const withContainer = (Component: React.ComponentFactory<any, any>) => () => {
  const { theme } = useThemePrefs(); 

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="sm"
        disableGutters
      >
        <CssBaseline/>
        <Component />
      </Container>
    </ThemeProvider>
  );
}

export const App = withContainer(() => {
  return (
    <Adventure/>
  );
});

export default App;