export const firebaseAdminSdk = {
  "com-f5-parm": {
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "private_key_id": "36a1aacde2f910ad3557b0e80cce3fb83d7f6868",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDxw5p8/aE80swY\nvqWkq68z6jnEnYX0DIXgAVrm+Q7/rZA4CNoKNt9sNV/zfeeX5fKKNH79afgeYH1X\nPM7tkaJD55nFo7KVZWt5GIlPwA30ZGwwqSYxkduWVhU7og30MRUWHRcjxUR6rNax\nR7mzr3nb16T3VjBwL7Ere003Pd/ZAWvQ6fp9XzbDZuSysZAVEeqWRcXDFevFZVrt\ndECM3vohj46bhoVhyGKC86CSkTgxr1+stMs4nrotWy3ED7wx8LWynhGx2DJd9bIz\n2I/lNbhRwk67sHmzabRaaindFDKdHt0JaBktSq/TvGUAdGniqUMqAVZAErrK1nNT\n0R6XPRRzAgMBAAECggEAD3P+QvD/RmD+9LHy6oSizUGycZPUBRZr1Dah9dODluok\nJsDCFxcMzuDWgjzQ+c+T4iua1l3YozBtso7Ldznw0kBSfuSIvK3f4bykwjk3ayCk\nHbQiIdb93y0fbFFebPZaL7CqUvn/94tmqaw4/XhA5MFy5PBYZkNWIr5d4CMPVNJ3\noPiX88uArOxM7fMxwesmnoC8HhZEGKKwU0yp2fV0zP/6wfItPzdXeO0rYTGPz4Pw\nOD9pzw3psjna1HQczsdKXu86WERyP8/Fw3QKg/SzXPW+WnvyWaMn1SSBGkmsuWsp\nncI56ECRXlWMZhfvPJmJ80NSnZmNnnhS0U2mxwlfGQKBgQD5xc0SbFDSf2arTBjp\n4UoyXgoD9jc8nHkffbWvn9cV8g0K2qIv5KNA38VU9QrAsGZkPM4mq2FRoVsWmEtF\nR3ez85uThPailS+8SMgX6S8CxnbPR2lR87l90yu4Ri1tpCJZaKEHR5/Ux1Kbz0R0\nrMD3yzPR7rn3Sc8D5r6J9K11FQKBgQD3yq/TU2WCXjg2VHA17ouc/BPAZ9PbKrVL\nYzePPOLPkBS13sepEQ/xxR7BDR7CPaYiO22DtKyl9NXrZUcbYEqdBOLCKcbyOHxI\n9MsL/72pCWypq0LD6p6VepKz6L6eKRa6sfW4F/65oD/RZzC7bTpVtd8QRR0qanto\nufwGcgdVZwKBgQCSzHSI3WxrK0G2b9U8sJKhI7ATQASa9QQjRAkinXkWhtqvhGxK\nZcClgl9CXXriYUrsouguxg8+oO0eTIgAwc5Sz1yiyUUDrVcO/F/N3dkpcmelz7zQ\n08QzdYd+mJBenlcp4VqzIp+HuVaOCLWdNWN8v8tHGV3nP1QSYO2fu1SvOQKBgQCo\nv1CkEtxDPxT68uAD6nMLsqNk2ZBuZhRWxbe1hVpUziM8g/3mxibcmEhMYjn7P+6e\ny2fWVLlqv2TbTPpyy02kxwoB/l9s30jMimCoyD4KGs/0cBwkvefHllTHzo3ZudOv\nyrey6ixfj6FGYwbN7SHX8uZ49whYtXFbraTTYt5ZqwKBgQDwNqG+7W1w9cUvwDvi\nQBPCKuLmqqGpOakWzE233c2wUBV5pgsX2jR1ZcUIpCI6F8G4NrbGDhc5AmB2N4K4\nZTdCFBEhpt76kYh/qA7QZOtpCDYwhidbymvCFdn6hYm+Tv87Go+0vvYRWgNJGJOY\nDV7L/B6NIeQH/JjxBmMtprHFxA==\n-----END PRIVATE KEY-----\n",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-s1kin%40com-f5-parm.iam.gserviceaccount.com",
    "type": "service_account",
    "project_id": "com-f5-parm",
    "client_email": "firebase-adminsdk-s1kin@com-f5-parm.iam.gserviceaccount.com",
    "token_uri": "https://oauth2.googleapis.com/token",
    "client_id": "110903525094118609852"
  }
};