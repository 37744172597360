import { FilterOperator } from "../filter-operators";

export const includesFilterOperator:
  FilterOperator<string, string>
 = {
  operator: '~=',
  filterFnFactory: n => (left, right) => {
    const lvalue = n[left] || '';
    const rvalue = right || '';
    return lvalue.includes(rvalue);
  }
}

