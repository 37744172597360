export const setsFeatureSetName = 'f5.core.sets';
export const setsPluginName = 'parm.sets';
export const setsPluginVersion = 'v1.0.0';
// [TODO] rename to fit the convention
// set by the createSetCommand below.
// eg. operators.com.parm.include
export const setsIncludeOperator = `${setsPluginName}.include`;
export const setsExcludeOperator = `${setsPluginName}.exclude`;
export const setsCreateSetCommand = `commands.${setsPluginName}.create-set`;

/**
 * this is data that must exist
 * on any node that defines its
 * type as [pluginName]
 */
export interface SetNodeTypeData {
  /** 
   * parm.sets set schema.
   * this defines the schema
   * for the additional data stored
   * on nodes acting as 'set' nodes
   */
  [setsPluginName]: {
    /** the version of the plugin,
     * in semvar format */
    version: string,
    /** the ids this set contains */
    ids: string[],
    /** the user friendly name 
     * of the set */
    name: string,
  }
}

/**
 * this is metadata that may exist
 * for any node of any type
 * if this plugin is registered
 * for use by the application.
 */
export interface SetNodeMetadata {
  /** 
   * parm.sets set schema.
   * this defines the schema
   * for additional data which
   * _may_ be stored on any given node
   */
  [setsPluginName]?: {
    /** the version of the plugin,
     * in semvar format */
    version: string,
    /** the sets this node belongs to
     * by set node id */
    sets: string[],
  }
}