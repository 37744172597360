import { createTheme, ThemeOptions } from '@material-ui/core/styles'; 
import { useState, useMemo } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { useNode } from './firebase';
import { storage } from './storage';

interface ThemeState {
  isDark: boolean,
  isTop: boolean,
}

const initialThemeState: ThemeState = {
  isDark: storage.isDark(),
  isTop: false,
}

let setThemeState: React.Dispatch<React.SetStateAction<ThemeState>> = () => { throw new Error(`You must call useThemePrefs first.`); };

const useThemeState = singletonHook(initialThemeState, () => {
  const [state, setState] = useState(initialThemeState);
  setThemeState = (state: ThemeState) => {
    storage.setIsDark(state.isDark);
    setState(state);
  };
  return state;
});

export const useThemePrefs = () => {
  const adminSettingsNode = useNode('admin.settings');
  const state = useThemeState();
  const { isDark, isTop } = state;
  let adminSettings: any = {};
  if (
    adminSettingsNode 
    && adminSettingsNode.data
    && adminSettingsNode.data.settings
  ) {
    const { data: { settings } } = adminSettingsNode;
    const colorRegex = /^\#((\d|\w){3}$)|((\d|\w){6}$)/;
    const colorAccent = settings['color.accent'].value;
    if (colorRegex.test(colorAccent)) {
      adminSettings.secondary = {
        main: colorAccent,
      }
    }
    const colorBackground = settings['color.background.default'].value;
    if (colorRegex.test(colorBackground)) {
      adminSettings.background = {
        default: colorBackground,
      }
    }
  }
  const theme = useMemo(() =>
    createTheme({
      palette: {
        type: isDark ? 'dark' : 'light',
        ...adminSettings,
      },
      typography: {
        button: {
          textTransform: 'none'
        }
      }
    }), [
      isDark,
      isTop,
      adminSettingsNode,
    ] 
  );

  const toggleDark = () => setThemeState({
    ...state,
    isDark: !isDark,
  });
  const toggleTop = () => ({
    ...state,
    isTop: !isTop,
  });

  return {
    theme,
    isDark,
    isTop,
    toggleDark,
    toggleTop,
  };
}

interface SortState {
  sortAscending: boolean;
}

const initialSortState: SortState = {
  sortAscending: storage.sort() || false, 
}

export const useSortState = () => {
  const [state, setState] = useState(initialSortState);
  const toggleSort = () => {
    storage.toggleSort();
    setState({
      sortAscending: storage.sort(),
    });
  };
  return {
    ...state,
    toggleSort,
  };
};
