import { isDefined } from '@parm/util';
import { NodeEntity } from '../../Node/NodeDefinition';
import { Hook, Hooks } from './hooks.interface';

/** empty by default */
export const viewHooks: Hooks<ViewHookFn> = {
};
const hooks = viewHooks;

/**
 * this is a hook which determines
 * whether or not to show this
 * node to the user. nodes is
 * passed in case the hook impl
 * requires checking any data
 * that may be stored in the entirety
 * of the db in order to determine
 * node's elligibility for viewing.
 */
export type ViewHookFn = (
  node: NodeEntity,
  nodes: Array<NodeEntity>
) => boolean;

/**
 * register new view filter hooks that may be used.
 * 
 * when a plugin is registered, it may also
 * register a new view filter hook which
 * will then be called on any node before
 * returning it from the useData hook.
 */
export const registerViewHook = 
  (_hook: Hook<ViewHookFn>) => {
    const hook = 
      hooks[_hook.hookName];
    if (hook) {
      const msg = [
        `Could not add viewHook.`,
        `'${hook}' is already bound.`,
      ].join(' ');
      throw new Error(msg);
    }
    hooks[_hook.hookName] = _hook;
  }

/**
 * filters nodes based on registered viewHooks
 */
export const viewHookFilter = (nodes: Array<NodeEntity>) => {
  const hookKeys = Object.keys(hooks);
  if (hookKeys.length === 0) {
    return nodes;
  }
  const _nodes = hookKeys.reduce((prevArr, curKey) => {
    const filterFn = (node: NodeEntity) => hooks[curKey].hookFn(node, nodes);
    const nextArr = prevArr
      .filter(filterFn)
      .filter(isDefined)
    return nextArr;
  }, nodes);
  return _nodes;
};