import { FilterNodeDataType } from '../../filter/filter-operators';
import {
  GrantNodeTypeData,
  grantPluginName,
  grantPluginVersion
} from '../grants/grants.interface';
import { NodeScaffold } from '../../Node/NodeDefinition';
import { SetNodeTypeData, setsIncludeOperator, setsPluginName, setsPluginVersion } from '../sets/sets.interface';
import { UserNodeTypeData, usersPluginName, usersPluginVersion } from './users.interface';


export const createUserScaffold = (email: string) => {
  const userId = `users.${email}`;
  const nodes: NodeScaffold[] = [];
  const user: NodeScaffold<UserNodeTypeData> = {
    id: userId,
    text: `this defines a user node for ${userId}`,
    type: 'user',
    data: {
      [usersPluginName]: {
        version: usersPluginVersion,
        name: userId,
        email,
      }
    },
    metadata: {
      [grantPluginName]: {
        version: grantPluginVersion,
        edit: [],
        view: []
      }
    },
  };
  nodes.push(user);

  const userSelfFilter: NodeScaffold<FilterNodeDataType> = {
    id: `${userId}.self-filter`,
    text:
      'this defines a filter which matches' +
      ' all nodes with id prefix' +
      ` ${userId}.`,
    type: 'filter',
    data: {
      filter: [
        {
          left: 'id',
          op: '~=',
          right: `${userId}`
        }
      ]
    },
    metadata: {
      [grantPluginName]: {
        version: grantPluginVersion,
        edit: [],
        view: []
      }
    },
  };
  nodes.push(userSelfFilter);

  const userSelfGrant: NodeScaffold<GrantNodeTypeData> = {
    id: `${userId}.grants.self`,
    text:
      'this defines a grant which allow a user to' +
      ' interact with the node that represents' +
      ' their user configuration.',
    type: grantPluginName,
    data: {
      [grantPluginName]: {
        version: grantPluginVersion,
        actions: ['view', 'edit', 'grant'],
        grantee: userId,
        grantor: userId,
        target: `*${userSelfFilter.id}`
      }
    },
    metadata: {
      [grantPluginName]: {
        version: grantPluginVersion,
        edit: [],
        view: []
      }
    },
  };
  nodes.push(userSelfGrant);

  const userPostsFilter: NodeScaffold<FilterNodeDataType> = {
    id: `${userId}.feeds.posts`,
    text:
      'this defines a filter which matches' +
      ' all nodes created by user' +
      ` ${userId}.`,
    type: 'filter',
    data: {
      filter: [
        {
          left: 'creatorId',
          op: '~=',
          right: `${userId}`
        }
      ]
    },
    metadata: {
      [grantPluginName]: {
        version: grantPluginVersion,
        edit: [],
        view: []
      }
    },
  };
  nodes.push(userPostsFilter);

  const userPostsGrant: NodeScaffold<GrantNodeTypeData> = {
    id: `${userId}.grants.posts`,
    text:
      `this defines a grant which allow ${userId} to` +
      ' interact with nodes created by' +
      ` ${userId}.`,
    type: grantPluginName,
    data: {
      [grantPluginName]: {
        version: grantPluginVersion,
        actions: ['view', 'edit', 'grant'],
        grantee: userId,
        grantor: userId,
        target: `*${userPostsFilter.id}`
      }
    },
    metadata: {
      [grantPluginName]: {
        version: grantPluginVersion,
        edit: [],
        view: []
      }
    },
  };
  nodes.push(userPostsGrant);

  const supportersOnlySet: NodeScaffold<SetNodeTypeData> = {
    id: `${userId}.sets.supporters-only`,
    text:
      'this defines a set' +
      ` visible only to supporters of ${userId}`,
    type: 'set',
    data: {
      [setsPluginName]: {
        version: setsPluginVersion,
        ids: [],
        name: `${userId}.sets.supporters-only`
      }
    },
    metadata: {
      [grantPluginName]: {
        version: grantPluginVersion,
        edit: [],
        view: []
      }
    },
  };
  nodes.push(supportersOnlySet);

  const supportersSet: NodeScaffold<SetNodeTypeData> = {
    id: `${userId}.sets.supporters`,
    text:
      'this defines a set of supporters for' +
      ` ${userId}. anyone in this set` +
      ` can view ${userId} supporters only posts`
    ,
    type: 'set',
    data: {
      [setsPluginName]: {
        version: setsPluginVersion,
        ids: [],
        name: `${userId}.sets.supporters`
      }
    },
    metadata: {
      [grantPluginName]: {
        version: grantPluginVersion,
        edit: [],
        view: []
      }
    },
  };
  nodes.push(supportersSet);

  const supportersOnlyPostsFilter: NodeScaffold<FilterNodeDataType> = {
    id: `${userId}.feeds.supporters-only`,
    text:
      'this defines a feed which matches' +
      ' nodes only visible to supporters by' +
      ` ${userId}.`,
    type: 'filter',
    data: {
      filter: [
        {
          left: supportersOnlySet.id,
          op: setsIncludeOperator,
          right: '',
        }
      ]
    },
    metadata: {
      [grantPluginName]: {
        version: grantPluginVersion,
        edit: [],
        view: []
      }
    },
  };
  nodes.push(supportersOnlyPostsFilter);

  const supportersGrant: NodeScaffold<GrantNodeTypeData> = {
    id: `${userId}.grants.supporters`,
    text:
      `this defines a grant which allow supporters-only to` +
      ' view the supporters-only set'
      ,
    type: grantPluginName,
    data: {
      [grantPluginName]: {
        version: grantPluginVersion,
        actions: ['view'],
        grantee: userId,
        grantor: userId,
        target: `*${supportersOnlyPostsFilter.id}`
      }
    },
    metadata: {
      [grantPluginName]: {
        version: grantPluginVersion,
        edit: [],
        view: []
      }
    },
  };
  nodes.push(supportersGrant);


  return nodes;
};