import { CommandDefinition } from "../../../core/commands/commands";
import { Options } from "../../../extern/useForm";
import { createNode } from "../../../firebase";
import { createUserScaffold } from "../create-user-scaffold";
import {  usersCreateUserCommand, usersPluginVersion } from "../users.interface";

const CommandOptions = {
  userId: {
    title: 'user-id',
    path: ['user-id'],
    description: `
this is the unique user id that will globally
refer to your user. it can never be changed.
however, you may create any number of profiles
with whatever name you would like.
`.replace('\n', ' '),
    value: '',
    multiline: false,
  },
  email: {
    title: 'email',
    path: ['email'],
    description: `
the associated email with this user
`.replace('\n', ' '),
    value: '',
    multiline: false,
  },
}

export type CreateUserCommandOptions = typeof CommandOptions;

// throws if CommandOptions not assignable
// to Options.
const _compilerCheck: Options = CommandOptions;

export const createUserCommand: CommandDefinition<CreateUserCommandOptions> = {
  commandName: usersCreateUserCommand,
  options: CommandOptions,
  version: usersPluginVersion,
  description:
`this command allows you to generate
users.
`,
  execute: (options) => {
    const nodes = createUser(options);
    const innerAsync = async () => {
      for (const node of nodes) {
        const result = await createNode(node);
        if (result) {
          console.log(`[INFO] Created node: `, result.option);
        } else {
          console.error(`[ERROR] Failed to create node: `, node);
        }
      }
    }
    innerAsync();
  }
}

export type CreateUserParams = {[Property in keyof CreateUserCommandOptions]: any};
export const createUser = (params: CreateUserParams) => {
  const { email } = params;
  return createUserScaffold(email).map(n => ({
    ...n, parent: 'navigation', creatorId: 'admin.proto'
  }));
}