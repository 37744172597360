import { CommandDefinition } from "../../../core/commands/commands";
import { Options } from "../../../extern/useForm";
import { createNode } from "../../../firebase";
import { NodeCreateProps } from "../../../Node/NodeDefinition";
import { SetNodeTypeData, setsCreateSetCommand, setsIncludeOperator, setsPluginName, setsPluginVersion } from "../sets.interface";
import { Kebab } from 'case-changes';
import { FilterNodeDataType } from "../../../filter/filter-operators";

const CommandOptions = {
  setName: {
    title: 'set name',
    path: ['set-name'],
    description: `
this will be the name of your set. the id for the set node
will be generated from this value. for instance, if
you name your set 'one liners' then the set node id will be
'sets/one-liners'. you will use the \`set name\` to search for 
your set when adding items to it. also, i will add a 'feeds/one-liners'
route for you to easily find the items in your set. simply go to
'/feeds/feeds.oneliners' to view it.
`.replace('\n', ' '),
    value: '',
    multiline: false,
  }
}

export type CreateSetCommandOptions = typeof CommandOptions;

// throws if CommandOptions not assignable
// to Options.
const _compilerCheck: Options = CommandOptions;

export const createSetCommand: CommandDefinition<CreateSetCommandOptions> = {
  commandName: setsCreateSetCommand,
  options: CommandOptions,
  version: setsPluginVersion,
  description:
`this command allows you to generate
sets to organize your nodes. it will also
generate a feed you can use to view
nodes in that set.
`,
  execute: (options) => {
    const nodes = createSet(options);
    const innerAsync = async () => {
      for (const node of nodes) {
        const result = await createNode(node);
        if (result) {
          console.log(`[INFO] Created set: `, result.option);
        } else {
          console.error(`[ERROR] Failed to create set: `, node);
        }
      }
    }
    innerAsync();
  }
}

export type CreateSetParams = {[Property in keyof CreateSetCommandOptions]: any};
export const createSet = (params: CreateSetParams) => {
  const { setName } = params;
  const id = Kebab.getStringKebabCased(setName);
  const filterId = `feeds.${id}`;
  const setId = `sets.${id}`;

  const setData: SetNodeTypeData = {
    [setsPluginName]: {
      version: setsPluginVersion,
      ids: [],
      name: setId,
    }
  };
  const set: NodeCreateProps = {
    id: setId,
    parent: 'navigation',
    type: 'set',
    text:
`
defines the '${setName}' set.

click [here](/feeds/${filterId}) to view the related feed.
    ` ,
    data: setData,
  };

  const filterData: FilterNodeDataType = {
    filter: [{
      op: setsIncludeOperator,
      left: id,
      right: '',
    }],
  };
  const feed: NodeCreateProps = {
    id: `feeds.${id}`,
    parent: 'navigation',
    type: 'filter',
    text:
`
defines the '${setName}' feed.

click [here](/feeds/${filterId}) to view it.
    `,
    data: filterData,
  };

  return [set, feed];
}