import React from 'react';
import { useThemePrefs } from '../hooks';
import AceEditor, { IAceEditorProps } from 'react-ace';

import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/theme-xcode';
import 'ace-builds/src-noconflict/theme-twilight';
// import 'ace-builds/src/keybinding-vim';
// import 'ace-builds/src/mode-json';

interface CodeEditorProps {
  internalEditorProps: IAceEditorProps
}

export const CodeEditor = (props: CodeEditorProps) => {
  const { isDark } = useThemePrefs();
  const aceTheme = isDark ? 'twilight' : 'xcode';
  return (
      <AceEditor
        theme={aceTheme}
        {...props.internalEditorProps}
      />
  );
}