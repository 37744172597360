import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import App from './app/app';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    {/* <QueryParamProvider ReactRouterRoute={Route}> */}
    <Routes>
        <Route path="/" element={<App/>} />
        <Route path="/nodes/*" element={<App/>} />
    {/* </QueryParamProvider> */}
    </Routes>
  </BrowserRouter>
);