/**
### get node ids

so why do i do this "dereferencing" nonsense.

look, i'm very sorry.

when i first made the grant feature i
thought it would be really cool for a 
target to be a filter, user, or regular ol
node and for the target to be "expanded"
based off the context of what ype the node
was.

this was fine and worked great!

until i realized i would also need to write
grants that targeted literal filter nodes
and not just the nodes that a filter matched.

so, i needed a quick way to do both..

and since dereferencing is a known concept in 
computing, i just went with that.

again, very sorry.
*/