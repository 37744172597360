import Button from '@material-ui/core/Button';
import { useFields } from '@parm/react/use-field';
import { get } from '@prmichaelsen/ts-utils';
import React from 'react';
import { useData } from '../../firebase';
import { NodeEntity } from '../../Node/NodeDefinition';
import { GrantNodeTypeData } from '../grants/grants.interface';
import { SetNodeMetadata, SetNodeTypeData, setsPluginName, setsPluginVersion } from './sets.interface';
import { getSetByName } from './sets.utils';

/**
 * this feature is very poorly designed,
 * but allow me to explain the thought process
 * 
 * each node has a 'data' property.
 * this is loosely structured as anyone
 * and any application can add whatever they
 * would like to this data property.
 * 
 * to try and keep things from clashing,
 * we define plugins that are namespaced.
 * for example, any properties added or 
 * manipulated by this plugin will be 
 * sub-properties of `parm.sets`.
 * 
 * the `parm.sets` plugin implements the 
 * `f5.core.sets` feature set. according to
 * the `f5.core.sets` spec, nodes must have
 * a `f5.core.sets` property that includes
 * whatever sets that node is a member of.
 * 
 * `f5.core.sets` does not care about the 
 * underlying implementation. `f5.core.sets`
 * does not care if sets are stored on the
 * nodes or if sets are stored as a list of
 * nodes.
 * 
 * all that matters is the _protocol_ for 
 * transferring information from one 
 * application to another.
 * 
 * declaring that your application implements
 * `f5.core.sets` protocol mandates that you
 * provide this property when it is requested
 * by another application.
 * 
 * `parm.sets` is responsible for mapping
 * the internal structure to the expected
 * protocol conforming structure when
 * sending outgoing data.
 * 
 * here is potentially relevant, incredibly
 * verbose ramblings regarding the subject
 * 
 * https://rixfeed.app/nodes/rWfog2OB6IuNB6hc19hn
 */


/** parm.sets node schema.
 * this defines the schema
 * for the additional data stored
 * on nodes 
 */
type ItemNodeSchema = SetNodeMetadata['parm.sets'];

/** parm.sets set schema.
 * this defines the schema
 * for the additional data stored
 * on set nodes
 */
type SetNodeSchema = SetNodeTypeData['parm.sets'];

interface AddToSetProps {
  /** 
   * newline delimited list of
   * set names to add ids to
   */
  defaultSets?: string;
  /** 
   * newline delimited list of
   * node ids to add to sets
   */
  defaultIds?: string;
}

const validate = (val) => !!val && val.trim() !== '';
export const AddToSet = (props: AddToSetProps) => {
  const { 
    defaultIds = '',
    defaultSets = '',
  } = props;
  const {
    state: { nodes },
    updateNode,
  } = useData();

  const [sets, ids] = useFields([
    { label: 'sets', value: defaultSets, multiline: true, validate },
    { label: 'ids', value: defaultIds, multiline: true, validate },
  ])

  const submit = () => {
    const _nodes = nodes;
    const _getSetByName = (name) => getSetByName(_nodes, name);
    const getNodeById = (id) => 
      _nodes.find(n => n.id === id);
    const prepare = (values, func) => 
      values.split('\n').map(func).filter(i => !!i);
    let setNodes = prepare(sets.value, _getSetByName);
    let itemNodes = prepare(ids.value, getNodeById);
    const nodeIds = prepare(ids.value, i => i);
    const setNodeIds = setNodes.map(n => n.id);

    // update nodes  
    itemNodes = itemNodes.map(node => {
      const localSets: string[] = get(
        node, 
        o => o.data,
        o=> o[setsPluginName],
        o => o.sets
      ) || [];
      const setData: ItemNodeSchema = {
        version: setsPluginVersion,
        sets: [...new Set(localSets.concat(setNodeIds))],
      }

      return {
        ...node,
        data: {
          ...node.data,
          [setsPluginName]: setData,
        },
      }
    });

    // update sets
    setNodes = setNodes.map((node: NodeEntity<GrantNodeTypeData>) => {
      const localIds: string[] = get(
        node, 
        o => o.data,
        o => o[setsPluginName],
        o=> o.ids
      ) || [];
      const name = get(node, o => o.data, o => o[setsPluginName], o => o.name);
      const setData: SetNodeSchema = {
        version: setsPluginVersion,
        ids: [...new Set(localIds.concat(nodeIds))],
        name,
      };
    
      return {
        ...node,
        data: {
          ...node.data,
          [setsPluginName]: setData,
        },
      }
    });

    [...itemNodes, ...setNodes].forEach(async node => { await updateNode({
      id: node.id,
      data: node.data,
    })});
  };

  return (
    <div>
      {sets.field}
      {ids.field}
      <Button
        onClick={submit}
      >
        Submit 
      </Button>
    </div>
  );

};