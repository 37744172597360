import { isObject } from 'util';

export type Complete<T> = { [P in keyof T]-?: NonNullable<T[P]>};

export const not =
  <T>(predicate: (o: T) => boolean) =>
    (o: T) => !predicate(o);

export const isTruthy = (o: any) => !!o;

export const initConfig = <T>(config: T): Complete<T> => {
  const warnings: string[] = [];
  const validate = (path, o) => {
    Object.keys(o).forEach(key => {
      const val = o[key];
      if (isObject(val)) {
        validate(path ? `${path}.${key}` : key, val);
      }
      if (val === '' || val === undefined) {
        warnings.push(`Could not load configuration for '${key}'.`);
      }
    });
  }
  validate('', config);
  if (warnings.length > 0) {
    warnings.forEach(warning => console.warn(warning));
    throw new Error('Some configuration variables could not be loaded. Exiting.');
  }
  return config as any;
} 

export const range = size => [...Array(size).keys()]; 


/** 
 * David Walsh (https://davidwalsh.name/javascript-debounce-function)
*/
export const debounce = ({
  /** the function to debounce */
  func,
  /** time ms to wait */
  wait
}) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};


/**
 * type guard filter to remove
 * undefined items from array
 * 
 * example:
 * ```
 * type StringOrUndefined = string | undefined;
 * const arr1: Array<StringOrUndefined> = ['element', undefined];
 * // no compiler error
 * const arr2: Array<string> = arr1.filter(isDefined);
 * ```
 */
 export const isDefined = <T>(o: T | undefined): o is T =>
 o !== undefined;

/**
* Performs a deep merge of objects and returns new object. Does not modify
* objects (immutable) and merges arrays via concatenation.
*
* @param {...object} objects - Objects to merge
* @returns {object} New object with merged key/values
* @author https://stackoverflow.com/a/48218209/5013193
*/
export function deepMergeIgnoreArrays(...objects) {
  const isObject = obj => obj && typeof obj === 'object';
  
  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];
      
      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        // prev[key] = pVal.concat(...oVal);
        // dont merge arrays
        prev[key] = pVal;
      }
      else 
      if (isObject(pVal) && isObject(oVal)) {
        prev[key] = deepMergeIgnoreArrays(pVal, oVal);
      }
      else {
        prev[key] = oVal;
      }
    });
    
    return prev;
  }, {});
}
