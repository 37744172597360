import 'ace-builds/src-noconflict/ace';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/theme-twilight';
import 'ace-builds/src-noconflict/theme-xcode';
import MarkdownToJsx from 'markdown-to-jsx';
import React from 'react';
import AceEditor from 'react-ace';
import { NodeEditor } from './components/NodeEditor';
import { SignInGate as UnboundSignInGate, SignInGateProps } from './components/SignInGate';
import { Command } from './core/commands/Command';
import { components } from './core/register-component';
import { Field } from './Field/Field';
import { useThemePrefs } from './hooks';
import { Img } from './Img';
import { NodeEntity } from './Node/NodeDefinition';
import { NodeContext } from './NodeContext';
import { EmailRegister } from './plugins/users/components/EmailRegister';
import { SettingsEditor } from './Settings';
import UserId from './UserId';
import { useStyles } from './hooks/useStyles';


const SignInGate = (props: { gate: SignInGateProps['gate'], children: any }) => 
  <UnboundSignInGate {...props} SignInComponent={EmailRegister}/>;

interface MarkdownProps {
  children: any,
  node: NodeEntity,
}

export const Markdown = (props: MarkdownProps) => {
  const { node, children } = props;
  const { isDark } = useThemePrefs();
  const aceTheme = isDark ? 'twilight' : 'xcode';
  const classes = useStyles();
  const context = { node };
  return (
    <NodeContext.Provider value={context}>
    <MarkdownToJsx options={{
      forceBlock: false,
      overrides: {
        blockquote: ({ children, ...props }) => (
          <Typography
            {...props}
            variant="body2"
            color="textSecondary"
            className={classes.quote}
            component="div"
          >
            {children}
          </Typography>
        ),
        code: ({ children, className: lang, ...props }) => {
          if (!lang) {
            return (
              <code key={props.key}>{children}</code>
            )
          }
          return (
            <AceEditor
              maxLines={Infinity}
              mode={lang ? lang.split('-')[1] : ''}
              theme={aceTheme}
              value={children}
              readOnly
            />
          );
        },
        img: Img,
        ListItem,
        UserId,
        Settings: SettingsEditor,
        AceEditor: props => <AceEditor theme={aceTheme} {...props}/>,
        NodeEditor,
        SignInGate,
        Button,
        Command,
        ...components,
        CreateTime: props => 
          <Field.Time
            value={node.createTime}
            node={node}
            options={props}
          />,
        UpdateTime: props => 
          <Field.Time
            value={node.updateTime}
            node={node}
            options={props}
          />,
        Id: props => 
          <Field.Text
            value={node.id}
            node={node}
          />,
        CreatorId: props => 
          <Field.Text
            value={node.creatorId}
            node={node}
          />,
        Text: props => 
          <Field.Text
            value={node.text}
            node={node}
          />,
        Data: props => 
          <Field.Json
            value={JSON.stringify(node.data, null, 2)}
            node={node}
          />,
      },
    }}>
      {children}
    </MarkdownToJsx>
    </NodeContext.Provider>
  );
}