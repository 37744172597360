import React from 'react';
import { formatDate, formatRelative, formatTime } from '@parm/util';
import firebase from 'firebase/compat/app';
import { NodeEntity } from '../Node/NodeDefinition';
import { CodeEditor } from '../components/CodeEditor';

export interface FieldProps<T, P> {
  value: T,
  node: NodeEntity,
  options?: P,
}
type Component<T, P> = (props: FieldProps<T, P>) => JSX.Element;

interface TextProps {
}

interface TimeProps {
  format: 'relative' | 'date' | 'time'
}

interface JsonProps {
}
interface Field {
  Time: Component<firebase.firestore.Timestamp, TimeProps>,
  Text: Component<string, TextProps>,
  Json: Component<string, JsonProps>,
}
export const Field: Field = {
  Time: (
    props: FieldProps<firebase.firestore.Timestamp, TimeProps>
  ) => {
    const { 
      value, options: { format } = {}
    } = props;
    let formatValue;
    switch (format) {
      case 'date': {
        formatValue = formatDate;
        break;
      }
      case 'relative': {
        formatValue = formatRelative;
        break;
      }
      case 'time': {
        formatValue = formatTime;
        break;
      }
      case undefined: {
        formatValue = formatTime;
        break;
      }
      default:
        const _exhaustiveCheck: never = format;
    } 
    const timeDisplay =
      !value ?
        'None'
        :
        formatValue(
          value.toDate()
        );
    return (
      <span>
        {timeDisplay}
      </span>
    )
  },
  Text: (
    props: FieldProps<string, TextProps>
  ) => {
    const { value } = props;
    return (
      <span>
        {value}
      </span>
    );
  },
  Json: (
    props: FieldProps<string, JsonProps>
  ) => {
    const { value } = props;
    return (
      <CodeEditor
        internalEditorProps={{
          value,
          maxLines: Infinity,
        }}
      />
    );
  },
}