export const usersFeatureUserName = 'f5.core.users';
export const usersPluginName = 'parm.users';
export const usersPluginVersion = 'v1.0.0';
export const usersCreateUserCommand = `commands.${usersPluginName}.create-user`;

/**
 * this is data that must exist
 * on any node that defines its
 * type as [pluginName]
 */
export interface UserNodeTypeData {
  /** 
   * parm.users user schema.
   * this defines the schema
   * for the additional data stored
   * on nodes acting as 'user' nodes
   */
  [usersPluginName]: {
    /** the version of the plugin,
     * in semvar format */
    version: string,
    /** the user friendly name 
     * of the user */
    name: string,
    /** the user's email */
    email: string,
  }
}