import { NodeEntity } from '../../Node/NodeDefinition';
import { filterFnFactory } from '../../filter/filter-fn-factory';

/**
 * helper fn responsible for taking a target
 * node id and returning the associated 
 * node or nodes.
 * 
 * if the target id parameter is
 * of the format '*some-filter' then the
 * function will return the
 * node ids matched by that filter.
 * 
 * this is essentially "dereferencing"
 * the filter. see [more](./get-node-ids.md)
 */
export const getNodes = (
  targetId: string,
  nodes: Array<NodeEntity>
): Array<NodeEntity> => {
  const parts = targetId.split('*');
  const target = parts[parts.length - 1];
  const isDereferenced = parts.length > 1;
  const targetNode = nodes.find(n => n.id === target);
  if (!targetNode) 
    return [];
  if (isDereferenced) {
    const feedFilter = filterFnFactory(targetNode, nodes);
    return nodes.filter(feedFilter);
  }
  return [targetNode];
};
