import React from 'react';
import { useUserId } from './hooks/useUserId';

export default function UserId() {
  const [userId] = useUserId()
  return (
    <span>
      {userId}
    </span>
  );
}