const getById = (id: string, nodes: Array<NodeEntity>) => nodes.find(n => n.id === id);

const getNodesMock = (targetId: string, nodes: Array<NodeEntity>): Array<NodeEntity> => {
  const parts = targetId.split('*');
  const target = parts[parts.length - 1];
  const isDereferenced = parts.length > 1;
  const targetNode = getById(target, nodes);
  if (!targetNode) 
    return [];
  if (isDereferenced)
    return (targetNode as any).ids.map(id => getById(id, nodes));
  return [targetNode];
}

jest.mock('./grants.utils', () => {
  return {
    getNodes: jest.fn().mockImplementation(getNodesMock),
  }
});

import { NodeEntity } from '../../Node/NodeDefinition';
import {
  isGranted
} from './grants';
import {
  GrantNodeTypeData,
  grantPluginName,
  grantPluginVersion
} from './grants.interface';

const postThatIs = {
  visibleToFollowers:     'X3bZb1h',
  private:                'VbaB3ac',
  public:                 'qSb3BjP',
}
const userThat = {
  followsPatrick:         'bob',
  doesNotFollowPatrick:   'andy',
}
const setIds = {
  patrickFollowers:       'patrick-followers',
  patricksBlog:           'patricks-blog',
  patricksPosts:          'patricks-posts',
  public:                 'public',
  grants:                 'grants',
  users:                  'users',
}

const posts = [
  {
    id: postThatIs.visibleToFollowers,
    text: 'saw tame impala at the innings festival :)',
    visibility: 'private',
    type: 'post',
  },
  {
    id: postThatIs.private,
    text: 'food journal: 165 lb today',
    visibility: 'private',
    type: 'post',
  },
  {
    id: postThatIs.public,
    text: 'food rec: the daily jam',
    visibility: 'public',
    type: 'post',
  },
];


const users = [
  {
    id: 'patrick',
    visibility: 'public',
    type: 'user',
  },
  {
    id: userThat.followsPatrick,
    visibility: 'public',
    type: 'user',
  },
  {
    id: userThat.doesNotFollowPatrick,
    visibility: 'public',
    type: 'user',
  },
  {
    id: 'ryan',
    visibility: 'public',
    type: 'user',
  },
];

/** people in patricks followers */
const patricksFollowersSet = [
  userThat.followsPatrick, 'andy',
];

/** 
 * posts patrick has made available
 * to patrick's followers
 */
const patricksBlogSet = [
  postThatIs.visibleToFollowers,
];

/** 
 * all of patrick's posts
 */
const patricksPostSet = [
  postThatIs.visibleToFollowers,
  postThatIs.private,
  postThatIs.public,
];

/** 
 * all public nodes
 */
const publicPostsSet = [
  postThatIs.public,
];

/**
 * the interface for
 * the data[grantPluginName]
 * property
 */
const globalGrants: Partial<NodeEntity<GrantNodeTypeData>>[] = [
  {
    id: 'public-grant',
    data: {
      [grantPluginName]: {
        version: grantPluginVersion,
        grantor: 'admin',
        grantee: `*${setIds.users}`,
        actions: ['view'],
        target: `*${setIds.public}`,
      }
    },
    text: 'admin grants all users ' +
      'permission to view ' +
      'public posts',
    type: grantPluginName
  },
  {
    id: 'admin-patrick-grant',
    data: {
      [grantPluginName]: {
        version: grantPluginVersion,
        grantor: 'admin',
        grantee: 'patrick',
        actions: ['view', 'grant-view'],
        target: `*${setIds.patricksPosts}`,
      }
    },
    text:
      'admin grants patrick ' +
      'permission to view ' +
      'and grant view for ' +
      'his own posts.',
    type: grantPluginName
  },
  {
    id: 'patrick-followers-grant',
    data: {
      [grantPluginName]: {
        version: grantPluginVersion,
        grantor: 'patrick',
        grantee: `*${setIds.patrickFollowers}`,
        actions: ['view'],
        target: `*${setIds.patricksBlog}`,
      }
    },
    text:
      'patrick grants his followers ' +
      'permission to view ' +
      'his blog posts.',
    type: grantPluginName
  }
];

const sets = [
  {
    id: setIds.patrickFollowers,
    ids: patricksFollowersSet,
    type: 'filter',
  },
  {
    id: setIds.patricksBlog,
    ids: patricksBlogSet,
    type: 'filter',
  },
  {
    // in reality, this would be a
    // filter that checks 
    // n.creatorId === patrick
    // for testing purposes,
    // we define a static set
    id: setIds.patricksPosts,
    ids: patricksPostSet,
    type: 'filter',
  },
  {
    // in reality, this would be a
    // filter that checks 
    // n.visibility !== private
    // for testing purposes,
    // we define a static set
    id: setIds.public,
    ids: publicPostsSet,
    type: 'filter',
  },
  {
    // in reality, this would be a
    // filter that checks 
    // n.type === grant
    // for testing purposes,
    // we define a static set
    id: setIds.grants,
    ids: globalGrants.map(n => n.id),
    type: 'filter',
  },
  {
    // in reality, this would be a
    // filter that checks 
    // n.type === user
    // for testing purposes,
    // we define a static set
    id: setIds.users,
    ids: users.map(n => n.id),
    type: 'filter',
  },
];

const db = [...users, ...posts, ...sets, ...globalGrants];
describe('grants', () => {
  describe('isGranted', () => {

    it('patrick can view patrick\'s node', () => {
      expect(isGranted({
        grantee: 'patrick',
        action: 'view' ,
        target: postThatIs.private,
        nodes: db as any,
      })).toBe(true);
    });

    it('bob cannot view private node', () => {
      expect(isGranted({
        grantee: userThat.followsPatrick,
        action: 'view' ,
        target: postThatIs.private,
        nodes: db as any,
      })).toBe(false);
    });

    it('bob can view follower node', () => {
      expect(isGranted({
        grantee: userThat.followsPatrick,
        action: 'view' ,
        target: postThatIs.visibleToFollowers,
        nodes: db as any,
      })).toBe(true);
    });

    it('andy can view public node', () => {
      // act
      const actual = isGranted({
        grantee: userThat.doesNotFollowPatrick,
        action: 'view' ,
        target: postThatIs.public,
        nodes: db as any,
      });

      // assert
      expect(actual).toBe(true);
    });

  });
});