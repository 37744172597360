import { Options } from "../../extern/useForm";
import { Camel } from 'case-changes';
import { deepMergeIgnoreArrays } from "@parm/util";

/** 
 * [NOTE] if i make commands modify entities
 * through redux, it will be very easy
 * to debug what changes they make to the system.
 * consider doing this.
 * 
 * i should also enable dry runs to a
 * similar effect.
 */

export interface CommandDefinition<TOptions extends Options> {
  options: TOptions,
  commandName: string,
  version: string,
  description: string,
  execute: (options: {[Property in keyof TOptions]: any}) => void,
}

interface Commands {
  [commandName: string]: CommandDefinition<any>,
}

export const commands: Commands = {
};

interface CommandManager {
  [key: string]: CommandManager | CommandDefinition<any>['execute'],
}
export const commandManager: CommandManager = {};

const updateCommandManager = (command: CommandDefinition<any>) => {
    const path = command.commandName.split('.').slice(1).join('.');
    const [first, ...paths] = path.split('.');
    const [last, ...rest] = paths.reverse();
    const commandName = Camel.getStringCamelCased(last);
    const namespace = Camel.getStringCamelCased(first);
    function convertDotPathToNestedObject() {
      return rest.reduce((acc, part) => {
        const _key = Camel.getStringCamelCased(part);
        return { 
          [_key]: acc
        };
      }, { [commandName]: command as any });
    }
    const nestedCommandObject = 
      convertDotPathToNestedObject();
    commandManager[namespace] = deepMergeIgnoreArrays(
      commandManager[namespace] || {},
      nestedCommandObject,
    );
  return commandManager;
}

/**
 * register new commands that may be used.
 * this is designed in a way that new plugins
 * may define new commands.
 * when a plugin is registered, it will also
 * register the new command which
 * will then be available for use in the 
 * application.
 */
export const registerCommand = 
  <TOptions extends Options>(command: CommandDefinition<TOptions>) => {
    const value = 
      commands[command.commandName];
    if (value) {
      const msg = [
        `Could not add command.`,
        `'${command.commandName}' is already bound.`,
      ].join(' ');
      throw new Error(msg);
    }
    commands[command.commandName] = command;
    updateCommandManager(command);
  }

export const clearCommands = () => {
  Object.keys(commands).forEach(key => {
    delete commands[key];
  });
  Object.keys(commandManager).forEach(key => {
    delete commandManager[key];
  });
};


if (window) {
  (window as any).commands = commandManager;
}